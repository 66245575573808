import Link from 'next/link'
import { useTranslation } from 'react-i18next';

type Props = {
    href: string;
    target: boolean;
    style: string
    text: string;
}
const CustomLink: React.FC<Props> = ({ href, target, style, text }) => {
    const { t } = useTranslation();

    return (
        <Link
        href={href}
        target={target ? '_blank' : ''}
        className={style}
      >
        {t(text)}
      </Link>
    )
}

export default CustomLink;