import { useMemo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { LoginResponse } from '@/types/apiTypes';
import { redirect } from '@/helper/functions';
import enterForm from '@/helper/shemaValidation/enter';
import { useAppDispatch } from '@/hooks/hooks';
import { IField } from '@/types/forms';
import { post } from '@/helper/axiosRequest';
import { setTenantId } from '@/redux/paymentReducer';
import { open } from '@/redux/rootReducer';
import { setLoad } from '@/redux/formsReducer';

import Field from '@/components/field';
import CustomLink from '@/components/UI/link';
import Button from '@/components/UI/button';
import ErrorComponent from '@/components/UI/errorField';

import style from './formEnter.module.scss';

type Props = {
  initialValues: any;
  fields: IField[];
};

const FormEnter: React.FC<Props> = ({ initialValues, fields }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: enterForm,
    onSubmit: (values) => {
      formik.resetForm();
      requestLogin({ email: values.email, password: values.password });
    },
  });

  const requestLogin = async (userData: {
    email: string;
    password: string;
  }) => {
    dispatch(setLoad(true));
    try {
      const response = await post<LoginResponse>(
        'login',
        userData,
        i18n.language
      );
      dispatch(setTenantId(response.tenant_id));
      sessionStorage.setItem('tenantID', JSON.stringify(response.tenant_id));
      redirect(router, 'subscribe');

      dispatch(open());
    } catch (error) {
      if (error) {
        formik.setFieldError('email', error as string);
      } else {
        formik.setFieldError('email', 'something was wrong');
      }

      dispatch(setLoad(false));
    }
  };

  const renderErrors = useMemo(() => {
    if (formik && formik.errors) {
      const errorArray = Object.values(formik.errors);
      return errorArray.map((error, index) => (
        <ErrorComponent key={index} error={error as string} />
      ));
    }
  }, [formik]);

  return (
    <form onSubmit={formik.handleSubmit} className={style.form}>
      <div className={style.form_wrapper}>
        {fields.map((field, index) => {
          return (
            <div className={style.form_field} key={index}>
              <Field
                id={field.name}
                placeholder={field.placeholder}
                label={field.label}
                type={field.type}
                name={field.name}
                onChange={formik.handleChange}
                value={formik.values[field.name]}
                error={formik.errors[field.name]}
              />
            </div>
          );
        })}
        <CustomLink
          href='/restorePassword'
          target={false}
          style={style.forgot_password}
          text='enterForm.forgotPassword'
        />
        <div className={style.wrapper_error}>{renderErrors}</div>
        <div className={style.wrapper_button}>
          <Button
            text={'enterForm.title'}
            type='submit'
            otherStyle={style.form_button}
          />
          <CustomLink
            href='/registration'
            target={false}
            style={style.form_button_registration}
            text='enterForm.buttonRegister'
          />
        </div>
      </div>
    </form>
  );
};

export default FormEnter;
