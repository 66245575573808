import {
  Country,
  Language,
  Login,
  Subscribe,
  Theme,
  CreatedTenantResponse,
  ThemesResponse,
  UserResponse,
  RestoranData,
} from '@/types/apiTypes';
import axios from 'axios';
import { newTenant, returnUser } from './functions';
import { IFormRegister, IFormRegistrationRestoran } from '@/types/forms';
import { request } from '@/api';

export const getAxios = async (
  url: string
): Promise<
  | string
  | ThemesResponse
  | Login
  | { payload: Country[] | Language[] | Theme[] | Subscribe[] }
> => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (err: any) {
    throw err.response.data.error;
  }
};

export const postAxios = async (url: string, body: any, local: string) => {
  try {
    const response = await axios.post(url, { body: body, local: local });

    return response.data.payload;
  } catch (err: any) {
    throw err.response.data.error;
  }
};

export const get = async <T>(
  url: string,
  key: string = 'payload',
  id?: number
): Promise<T> => {
  const response = (await getAxios(
    id ? `../api/get/${url}/${id}` : `../api/get/${url}`
  )) as unknown as Promise<{
    [k: string]: T;
  }>;

  return (await response)[key];
};

export const post = async <T>(
  url: string,
  data: any,
  lang: string
): Promise<T> => {
  const response = (await postAxios(
    `../api/post/${url}`,
    data,
    lang
  )) as unknown as Promise<T>;

  return response;
};

//posts request

export const createLogo = async (logo: any, lang: string, id: number) => {
  const response = await request(
    `${process.env.NEXT_PUBLIC_BASE_URL}settings/logotype-to-menu?tenant_id=${id}`,
    'POST',
    '',
    logo,
    'multipart/form-data',
    lang
  );

  return response;
};

export const createTheme = async (
  theme: FormData,
  lang: string,
  id: number
) => {
  const response = await request(
    `${process.env.NEXT_PUBLIC_BASE_URL}settings/theme_id?tenant_id=${id}`,
    'POST',
    '',
    theme,
    'multipart/form-data',
    lang
  );

  return response;
};
