import { ReactNode } from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/hooks/hooks';

import ThreeDots from '../threeDots';

import style from './button.module.scss';

type Props = {
  text: string;
  otherStyle?: string;
  children?: ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  func?: (params?: any) => void;
  disable?: boolean;
  link?: string;
};

const Button: React.FC<Props> = ({
  text,
  otherStyle,
  children,
  type,
  func,
  disable,
  link,
}) => {
  const { t } = useTranslation();
  const load = useAppSelector((state) => state.formState.load);

  const renderLink = () => {
    if (link) {
      return (
        <Link
          href={link}
          target={link.includes('http') ? 'blank' : ''}
          className={otherStyle ?? style.default}
        >
          {t(text)}
        </Link>
      );
    }
  };

  const renderContent = () => {
    if (link?.includes('http')) {
      return renderLink();
    } else if (!link?.includes('http')) {
      return link ? (
        renderLink()
      ) : (
        <button
          className={otherStyle ?? style.default}
          type={type ?? 'button'}
          disabled={disable}
          onClick={func}
        >
          {load && type === 'submit' ? (
            <div className={style.wrapper_loader}>
              <ThreeDots color='#fff' width={15} height={15} />
            </div>
          ) : (
            <>
              <span>{t(text)}</span>
              {children}
            </>
          )}
        </button>
      );
    }
  };

  return <>{renderContent()}</>;
};

export default Button;
