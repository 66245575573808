import classNames from 'classnames';
import style from './error.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  error: string;
};

const ErrorComponent: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();
  return (
    <span
      className={classNames(style.error, {
        [style.active]: error,
      })}
    >
      {t(error)}
    </span>
  );
};

export default ErrorComponent;
