import * as Yup from 'yup';

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

const enterForm = Yup.object().shape({
  email: Yup.string()
    .required('formRegistration.emailImportantError')
    .matches(emailRegex, 'formRegistration.emailErrorFormat')
    .email('formRegistration.emailErrorFormat'),
  password: Yup.string()
    .required('formRegistration.passwordError')
    .min(8, 'formRegistration.passwordErrorMin')
    .max(20, 'formRegistration.passwordErrorMax')
    .matches(/[a-z]/, 'formRegistration.passwordErrorLower')
    .matches(/[0-9]/, 'formRegistration.passwordErrorNumber'),
});

export default enterForm;
