import { useAppSelector } from '@/hooks/hooks';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import useWindowSize from '@/hooks/useWindowSize';
import { enterForm } from '@/helper/fields';
import { useTranslation } from 'react-i18next';
import { open } from '@/redux/rootReducer';

import ImageComponent from '@/components/UI/image';
import Button from '@/components/UI/button';
import FormEnter from '@/components/formEnter';
import SelectLanguage from '@/components/selectLanguage';

import Arrow from '@/assets/icons/Arrow.svg';

import style from './enter.module.scss';
import LoaderIcon from '../UI/loader';

const Enter = () => {
  const { t } = useTranslation();
  const active = useAppSelector((state) => state.rootState.enter);
  const dispatch = useDispatch();
  const { windowWidth } = useWindowSize();
  const initialValues = {
    email: '',
    password: '',
  };
  const loader = useAppSelector((state) => state.rootState.loader.language);

  const closeMenu = () => {
    dispatch(open());
    if (active && windowWidth <= 720) {
      document.body.style.overflow = 'auto';
    }
  };

  const renderLoader = () => loader && <LoaderIcon />;

  const renderContent = () =>
    !loader && (
      <>
        <div className={style.wrapper_nav}>
          <Button
            text={'404.button'}
            func={closeMenu}
            otherStyle={style.button_back}
          >
            <ImageComponent
              image={Arrow}
              alt={'arrow'}
              otherStyle={style.arrow}
            />
          </Button>
          <SelectLanguage languages={['UA', 'EN']} />
        </div>
        <div className={style.wrapper}>
          <h2 className={style.title}>{t('enterForm.title')}</h2>
          <FormEnter initialValues={initialValues} fields={enterForm} />
        </div>
      </>
    );

  return (
    <div
      className={classNames(style.enter, {
        [style.active]: active,
      })}
    >
      {renderLoader()}
      {renderContent()}
    </div>
  );
};

export default Enter;
