import style from './threeDots.module.scss';

type Props = {
  color: string;
  width: number;
  height: number;
};

const ThreeDots: React.FC<Props> = ({ color, width, height }) => {
  const styleDot = {
    backgroundColor: color,
    width: `${width}px`,
    height: `${height}px`,
  };

  return (
    <div className={style.wrapper}>
      <div className={style.dot} style={{ ...styleDot }}></div>
      <div className={style.dot_middel} style={{ ...styleDot }}></div>
      <div className={style.dot} style={{ ...styleDot }}></div>
    </div>
  );
};

export default ThreeDots;
