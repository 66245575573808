import axios from 'axios';

const login = process.env.NEXT_PUBLIC_LOGIN_API;
const password = process.env.NEXT_PUBLIC_PASSWORD_API;

export const request = (
  url: string,
  method: string,
  token: string,
  body?: any,
  contentType?: string,
  locale?: string
) => {
  const authorization = token
    ? `Bearer ${token}`
    : 'Basic ' + btoa(login + ':' + password);

  const headers = {
    Authorization: authorization,
    'Content-Type': !contentType ? 'application/json' : contentType,
    Locale: locale || 'uk',
  };

  const options = {
    headers: headers,
  };

  if (method === 'POST') {
    return axios.post(url, body, options);
  }

  return axios.get(url, options);
};
