import { useEffect, useState } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '@/hooks/hooks';
import { changeLang, setLoader } from '@/redux/rootReducer';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import arrow from '@/assets/icons/arrow.png';

import style from './selectLanguage.module.scss';
import { setMobileScroll } from '@/helper/functions';

type Props = {
  languages: string[];
};

const SelectLanguage: React.FC<Props> = ({ languages }) => {
  const { i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const lang = useAppSelector((state) => state.rootState.lang);

  const switchListLanguages = () => {
    setShow((prevState) => !prevState);
  };

  useEffect(() => {
    let currentLang = lang;
    if (lang === 'ua') {
      currentLang = 'uk';
    }

    if (router.locale !== currentLang) {
      i18n.changeLanguage(router.locale);
      dispatch(
        changeLang(router.locale === 'uk' ? 'ua' : (router.locale as string))
      );
    }
  }, [dispatch, i18n, lang, router.locale]);

  const selectLanguage = (e: any) => {
    setMobileScroll();
    if (lang !== e.target.innerText.toLowerCase()) {
      dispatch(changeLang(e.target.innerText));
      dispatch(setLoader({ title: 'language', status: true }));
    }
    if (e.target.innerText.toLowerCase() == 'ua') {
      router.push(router.pathname, router.asPath, { locale: 'uk' });
      i18n.changeLanguage('uk');
    } else {
      router.push(router.pathname, router.asPath, { locale: 'en' });
      i18n.changeLanguage(e.target.innerText.toLowerCase());
    }
  };

  const renderLanguages = () =>
    languages.map((language) => (
      <li
        key={language}
        className={style.languages_element}
        onClick={(event) => {
          switchListLanguages();
          selectLanguage(event);
        }}
      >
        {language}
      </li>
    ));

  return (
    <div className={style.switcher}>
      <div className={style.selector} onClick={switchListLanguages}>
        <span className={style.selector_lang}>{lang}</span>
        <Image src={arrow} alt='arrow' className={style.arrow} />
      </div>
      <ul
        className={classNames(style.languages, {
          [style.show]: show,
        })}
      >
        {renderLanguages()}
      </ul>
    </div>
  );
};

export default SelectLanguage;
