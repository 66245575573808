import { IField } from '@/types/forms';

export const registerFormFields: IField[] = [
  {
    name: 'login',
    type: 'text',
    label: 'formRegistration.loginLabel',
    placeholder: 'Login',
    errorText: 'formRegistration.loginError',
  },
  {
    name: 'password',
    type: 'password',
    label: 'formRegistration.passwordLabel',
    placeholder: 'formRegistration.passwordLabel',
    errorText: 'formRegistration.passwordError',
  },
  {
    name: 'confirmPassword',
    type: 'password',
    label: 'formRegistration.confirmPasswordLabel',
    placeholder: 'formRegistration.confirmPasswordPlaceholder',
    errorText: 'formRegistration.passwordError',
  },
  {
    name: 'email',
    type: 'email',
    label: 'formRegistration.emailLabel',
    placeholder: 'Eaterylite@email.com',
    errorText: 'formRegistration.emailImportantError',
  },
];

export const enterForm: IField[] = [
  {
    name: 'email',
    type: 'email',
    label: 'enterForm.labelEmail',
    placeholder: 'Eaterylite@email.com',
    errorText: 'formRegistration.emailImportantError',
  },
  {
    name: 'password',
    type: 'password',
    label: 'formRegistration.passwordLabel',
    placeholder: 'formRegistration.passwordLabel',
    errorText: 'formRegistration.passwordError',
  },
];

export const formRestoreEmail: IField[] = [
  {
    name: 'email',
    type: 'email',
    label: 'enterForm.labelEmail',
    placeholder: 'Eaterylite@email.com',
    errorText: 'formRegistration.emailImportantError',
  },
];

export const formRestorePassword: IField[] = [
  {
    name: 'password',
    type: 'password',
    label: 'formRegistration.passwordLabel',
    placeholder: 'formRegistration.passwordLabel',
    errorText: 'formRegistration.passwordError',
  },
  {
    name: 'confirmPassword',
    type: 'password',
    label: 'formRegistration.confirmPasswordLabel',
    placeholder: 'formRegistration.confirmPasswordPlaceholder',
    errorText: 'formRegistration.passwordError',
  },
];
