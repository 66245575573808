import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import ImageComponent from '../UI/image';
import OpenEye from '@/assets/icons/openEye.svg';
import CloseEye from '@/assets/icons/closeEye.svg';

import ThreeDots from '../UI/threeDots';

import style from './field.module.scss';

type Props = {
  id: string;
  label: string;
  type: string;
  name: string;
  placeholder: string;
  onChange: any;
  onBlur?: any;
  value: string;
  error: any;
  loader?: boolean;
};

const Field: React.FC<Props> = ({
  id,
  label,
  type,
  name,
  placeholder,
  onChange,
  onBlur,
  value,
  error,
  loader,
}) => {
  const { t } = useTranslation();
  const [customType, setCustomType] = useState(type);

  const switchType = () => {
    customType === 'password'
      ? setCustomType('text')
      : setCustomType('password');
  };

  return (
    <>
      <label className={style.field}>
        <span className={style.field_label}>{t(label)}</span>
        <input
          className={classNames(style.field_input, {
            [style.error]: error,
          })}
          id={id}
          type={customType}
          name={name}
          onBlur={onBlur}
          placeholder={t(placeholder)}
          onChange={onChange}
          value={value}
        />
        {type === 'password' && (
          <div className={style.eye} onClick={switchType}>
            <ImageComponent
              image={customType === 'password' ? OpenEye : CloseEye}
              alt='eye'
            />
          </div>
        )}
        {loader && (
          <div className={style.login_loader}>
            <ThreeDots color='#3d76f3' width={20} height={20} />
          </div>
        )}
      </label>
    </>
  );
};

export default Field;
