import Image from 'next/image';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';

type Props = {
  image: StaticImport | string;
  alt: string;
  otherStyle?: string;
  width?: number;
  height?: number;
};

const ImageComponent: React.FC<Props> = ({
  image,
  alt,
  otherStyle,
  width,
  height,
}) => {
  return (
    <Image
      src={image}
      alt={alt}
      className={otherStyle}
      width={width}
      height={height}
      loading='lazy'
    />
  );
};

export default ImageComponent;
